<template>
  <div class="person-dashboard">
    <div class="d-flex flex-wrap person-box">
      <div v-for="(item,i) in dashboardList" :key="i" class="person-item">
        <div class="person-card">
          <div class="p-name">
            <div class="pimg">
              <!-- 0男1女 -->
              <img
                v-if="item.sex==1"
                :src="item.personDeviceStatus == personViewWarningStatus ? headImg.personDangerNv : (item.personDeviceStatus == personViewOfflineStatus ? headImg.personInfoNv : headImg.personSucNv)"
              >
              <img
                v-if="item.sex==0"
                :src="item.personDeviceStatus == personViewWarningStatus ? headImg.personDangerNan : (item.personDeviceStatus == personViewOfflineStatus ? headImg.personInfoNan : headImg.personSucNan)"
              >
              {{ item.userName }}
            </div>
            <div class="pname">
              {{ item.resourceName }}
            </div>
          </div>
          <div
            class="p-detail"
            :class="[item.personDeviceStatus == personViewWarningStatus ? 'danger-bg-color' : (item.personDeviceStatus == personViewOfflineStatus ? 'info-bg-color' : '')]"
          >
            <div class="nodevice info-color" v-if="item.personDeviceStatus==undefined||item.personDeviceStatus == personViewNoneStatus">
              无设备
            </div>
            <div class="havedevixe" v-else>
              <div
                class="param"
                :class="[item.personDeviceStatus == personViewWarningStatus ? 'danger-color' : (item.personDeviceStatus == personViewOfflineStatus ? 'info-color' : 'suc-color')]"
              >
                <div>
                  呼吸：
                  <span class="text-success"> {{getBreathShow(item)}}</span>
                  <span v-if="item.breath!=-1"> RPM</span>
                </div>
                <div>
                  心率：
                  <span class="text-success"> {{getHeartShow(item)}}</span>
                  <span v-if="item.heart!=-1"> BMP</span>
                </div>
              </div>
              <div class="status">
                <div v-if="item.personDeviceStatus == personViewWarningStatus" :class="{'txtffffff':item.personDeviceStatus == personViewWarningStatus}">
                  <div class="item-list">
                    <div v-for="(deviceVo,i) in item.deviceVos.slice(0,4)" :key="i">
                      <p>
                        <img
                          :src="deviceVo.eventImg"
                        ></img>
                      </p>
                      <p>{{getDeviceShow(deviceVo,i,item.deviceVos)}}</p>
                    </div>
                  </div>
                </div>
                <div v-if="item.personDeviceStatus == personViewOfflineStatus" :class="{'txtffffff':item.personDeviceStatus == personViewOfflineStatus}">
                  <p><img class="only-img" :src="statusImg.lx"></p>
                  <p>设备离线</p>
                </div>
                <div v-if="item.personDeviceStatus == personViewNormalStatus" :class="{'txt0c0c0c':item.personDeviceStatus == personViewOfflineStatus}">
                  <p><img class="only-img" :src="statusImg.zc"></p>
                  <p>正常照护</p>
                </div>
              </div>
              <!-- <div>
                <div class="status">
                  <span v-for="(deviceVo,i) in item.deviceVos" :key="i">{{getDeviceShow(deviceVo,i,item.deviceVos)}}</span>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>


      <!-- </b-col>
    </b-row> -->
    <b-row class="mt-5 pagination-center">
      <b-col cols="12" sm="6" class="mt-5 d-flex justify-content-sm-end">
        <b-pagination v-model="queryParams.currentPage" :total-rows="queryParams.total" :per-page="queryParams.pageSize"
          first-number last-number class="mb-0 mt-5 mt-sm-0" prev-class="prev-item" next-class="next-item"
          @change="currentPageHandleChange">
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BCard, BFormTextarea, BFormRadioGroup,
  BPagination, BToast
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import request from '@/api/request'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    BFormRadioGroup,
    BPagination,
    ToastificationContent,
    BToast,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormTextarea,
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      selected: '0',
      currentPage: 1,

      props: {
        label: 'name',
        children: 'childNodes'
      },
      count: 1,

      groups: [],

      //分页实体
      queryParams: {
        currentPage: 1,
        pageSize: 64,
        total: null,
        tenantGroupId: null,
      },

      dashboardList: [],

      //性别
      headImg: {
        personSucNv: require('@/assets/images/pages/person/personSucNv.png'),
        personSucNan: require('@/assets/images/pages/person/personSucNan.png'),
        personDangerNv: require('@/assets/images/pages/person/personDangerNv.png'),
        personDangerNan: require('@/assets/images/pages/person/personDangerNan.png'),
        personInfoNv: require('@/assets/images/pages/person/personInfoNv.png'),
        personInfoNan: require('@/assets/images/pages/person/personInfoNan.png'),
      },
      // 状态
      statusImg: {
        lx: require('@/assets/images/pages/person/lx.png'), /*离线*/
        zc: require('@/assets/images/pages/person/zc.png'), /*正常*/
      },
      eventLvValue: 1,
      eventTypeOptions:[],
      warningEventImgOptions: [
        {
          value: 101,
          label: '摔倒警报',
          img: require('@/assets/images/pages/person/dd.png'),
        },
        {
          value: 209,
          label: '长时间未体动',
          img: require('@/assets/images/pages/person/cswd.png'),
        },
        {
          value: 103,
          label: '长时间无人',
          img: require('@/assets/images/pages/person/cswr.png'),
        },
        {
          value: 105,
          label: '长时间滞留',
          img: undefined,
        },
        {
          value: 201,
          label: '心率异常',
          img: require('@/assets/images/pages/person/xlyc.png'),
        },
        {
          value: 203,
          label: '呼吸异常',
          img: require('@/assets/images/pages/person/hxyc.png'),
        },
        {
          value: 205,
          label: '呼吸暂停',
          img: require('@/assets/images/pages/person/hxzt.png'),
        },
        {
          value: 207,
          label: '离床超时',
          img: require('@/assets/images/pages/person/lccs.png'),
        },
      ],

      personViewNoneStatus:0,
      personViewOfflineStatus:1,
      personViewNormalStatus:2,
      personViewWarningStatus:3,

    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.getDashboardList()
    }, 1 * 5 * 1000)
  },
  created() {
    this.eventTypeOptionsFormat();
    this.getDashboardList()
  },
  destroyed() {
  },

  methods: {
    getDashboardList() {
      request.get('tob/tenant/bPersonView/pagePsersonView', {
        currentPage: this.queryParams.currentPage,
        pageSize: this.queryParams.pageSize,
      })
        .then(res => {
          if (res.data.success) {
            this.dashboardList = res.data.data.data
            this.queryParams.total = res.data.data.count
            this.dataInit();
            console.log("dashboardList",this.dashboardList)
          }
        })
    },

    dataInit(){
      //设置警报图片
      if (this.dashboardList!=undefined&this.dashboardList.length>0){
        this.dashboardList.forEach(item => {
          if (item.personDeviceStatus==this.personViewWarningStatus){
            item.deviceVos.forEach(childItem => {
              var status=childItem.personViewDeviceStatus;
              if (status!=undefined&status==this.personViewWarningStatus){
                var img=this.getWarningImg(childItem.eventType)
                if (img!=undefined){
                  childItem.eventImg=img
                }
              }
            })
          }
        })
      }
    },

    currentPageHandleChange(currentPage) {
      this.queryParams.currentPage = currentPage
      this.getDashboardList()
    },

    getBreathShow(item) {
      var breath = item.breath;
      if (breath == undefined) {
        return "--"
      } else if (breath == -1) {
        return '<冲突>'
      } else {
        return breath
      }
    },

    getHeartShow(item) {
      var heart = item.heart;
      if (heart == undefined) {
        return "--"
      } else if (heart == -1) {
        return "<冲突>"
      } else {
        return heart
      }
    },

    getDeviceShow(deviceVo,i,deviceVos){
      if (deviceVo.noticeStatus == 1){
        var  eventType=deviceVo.eventType;
        if (eventType!=undefined){
          return this.eventTypeFormat(eventType)
        }else {
          return  "警报"
        }
      }else if (deviceVo.deviceStatus == 1){
        return  "离线"
      }
    },

    //格式化后端返回的事件类型枚举
    eventTypeOptionsFormat() {
      request.get('tob/bEventRecord/getEnumsByEventLv', {
        eventLvValue: this.eventLvValue,
      }).then(res => {
        if (res.data.success) {
          var enums = res.data.data;
          this.eventTypeOptions = [];
          for (const key in enums) {
            var value = enums[key];
            var enumObject = {
              value: key - 0,
              label: value,
            }

            this.eventTypeOptions.push(enumObject);
          }
        }
      })
    },

    //事件类型format
    eventTypeFormat(value) {
      if (value != undefined) {
        for (let item of this.eventTypeOptions) {
          if (item.value === value) {
            return item.label;
          }
        }
      }
      return value;
    },

    getWarningImg(value){
      if (value != undefined) {
        for (let item of this.warningEventImgOptions) {
          if (item.value === value) {
            return item.img;
          }
        }
      }
      return value;
    }

  },
}
</script>
<style lang="scss">
.person-dashboard {
  .suc-color {
    background-color: #EEEDF6;
  }
  .info-color {
    background-color: #EEEEEE;
  }
  .danger-color {
    background-color: #FBF3F2;
  }
  .danger-bg-color {
    background-color: #EF8F74;
  }
  .info-bg-color {
    background-color: #848484;
  }
  .txtffffff {
    color: #FFFFFF;
  }
  .txt0c0c0c {
    color: #0C0C0C;
  }
  .person-box {
    flex-wrap: wrap;

    .person-item {
      flex: 0 0 calc(20% - 20px);
      margin: 10px;
      min-width: 170px;
      background: #FFFFFF;
      box-shadow: 0px 5px 11px 6px rgba(129,129,129,0.05);
      border-radius: 6px;
      border: 1px solid #CECECE;
    }
  }

  .person-card {
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    background: #fff;
    border-radius: 0.428rem;
    color: #6e6b7b;

    .p-name {
      display: flex;
      line-height: 40px;
      justify-content: space-between;
      padding: 10px;
      .pimg {

        img {
          width: 40px;
          height: 40px;
          margin-right: 14px;
        }
      }

      .pname {
        font-size: 14px;
        color: #0C0C0C;
      }
    }

    .p-detail {

      .nodevice {
        text-align: center;
        height: 160px;
        line-height: 160px;
        font-size: 20px;
      }

      .havedevixe {
        height: 160px;

        .param {
          display: flex;
          height: 54px;
          align-items: center;
          justify-content: space-between;
          padding: 0 10px;
          div {
            color: #625F6E;
            span {
              color: #0C0C0C;
            }
          }

          img {
            vertical-align: middle;
            margin-right: 5px;
            width: 0.6vw;
          }
        }

        .status {
          margin-top: 20px;
          text-align: center;
          font-size: 14px;
          .only-img {
            width: 34px;
          }
          .item-list {
            display: flex;
            div {
              flex: 1;
              border-right: 1px solid #FFFFFF;
              &:last-child {
                border-right: transparent;
              }
              img {
                width: 26px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
